<template>
	<div>
		<el-row class="tac" :gutter="10">
			<el-col :span="8">
				<el-menu class="menu-nav" :default-active="aaa" @open="handleOpen" @close="handleClose"
					:unique-opened="true" @select="vvvv($event)" background-color="#20222A" active-text-color="#ffd04b"
					text-color="#fff" router :collapse="isCollapse" style="height: 100%; border: none">
					<!-- <el-menu-item index="/SchoolHome"> -->
					<el-menu-item index="/SchoolHome" v-if="isRole(['school'])">
						<i class="el-icon-location"></i>
						<span>首页</span>
					</el-menu-item>
					<el-menu-item index="/teacherHome" v-if="isRole(['teacher', 'agent'])">
						<!-- <el-menu-item index="/teacherHome"> -->
						<i class="el-icon-location"></i>
						<span>{{ isRole(['teacher']) ? "教师首页" : "首页" }}</span>
					</el-menu-item>

					<el-menu-item index="/AgentHome"  v-if="isRole(['agent', 'teacher', 'adv'])">
						<i class="el-icon-location"></i>
						<span>{{ isRole(['agent']) ? "代理首页" : "首页" }}</span>
					</el-menu-item>
					<!-- <el-submenu index="1"> -->
					<el-menu-item v-if="isRole(['school'])" @click.native="screen">
						<i class="el-icon-setting"></i>
						<span>数据大屏</span>
					</el-menu-item>
					<el-submenu index="1" v-if="isRole(['school'])">
						<template slot="title">
							<i class="el-icon-setting"></i>
							<span slot="title">班级管理</span>
						</template>
						<el-menu-item-group>
							<el-menu-item index="/ClassList">
								<i class="el-icon-warning"></i>
								<span slot="title">班级列表</span>
							</el-menu-item>
							<!-- <el-menu-item index="/CreateGrade">
                <i class="el-icon-warning"></i>
                <span slot="title">创建年级</span>
              </el-menu-item> -->
							<!-- <el-menu-item index="/historyClass">
                <i class="el-icon-warning"></i>
                <span slot="title">历史班级</span>
              </el-menu-item> -->
						</el-menu-item-group>
					</el-submenu>
					<!-- <el-menu-item index="/dataInfo"> -->
					<!-- <el-menu-item index="/dataInfo" v-if="Status=='teacher'?true:(Status=='teacherAgent'?true:false)">
                  <i class="el-icon-location"></i>
                  <span>数据管理</span>
               </el-menu-item> -->
					<!-- <el-submenu index="2"> -->
					<el-submenu index="2" v-if="isRole(['school'])">
						<template slot="title">
							<i class="el-icon-setting"></i>
							<span slot="title">教师管理</span>
						</template>
						<el-menu-item-group>
							<el-menu-item index="/teacherList">
								<i class="el-icon-warning"></i>
								<span slot="title">教师列表</span>
							</el-menu-item>
							<!-- <el-menu-item index="/addTeacher">
                <i class="el-icon-warning"></i>
                <span slot="title">添加教师</span>
              </el-menu-item> -->
						</el-menu-item-group>
					</el-submenu>

					<el-submenu index="3" v-if="isRole(['school', 'teacher', 'agent'])">
						<template slot="title">
							<i class="el-icon-setting"></i>
							<span slot="title">学生管理</span>
						</template>
						<el-menu-item-group>
							<!-- <el-menu-item index="/studentList"> -->
							<el-menu-item index="/studentList">
								<i class="el-icon-warning"></i>
								<span slot="title">学生列表</span>
							</el-menu-item>
							<!-- 							<el-menu-item index="/applyList">
								<i class="el-icon-warning"></i>
								<span slot="title">申请列表</span>
							</el-menu-item> -->
						</el-menu-item-group>
					</el-submenu>

					<el-submenu index="24" v-if="isRole(['school'])">
						<template slot="title">
							<i class="el-icon-setting"></i>
							<span slot="title">数据管理</span>
						</template>
						<el-menu-item-group>
							<el-menu-item index="/measurementData">
								<i class="el-icon-warning"></i>
								<span slot="title">测温数据</span>
							</el-menu-item>
							<el-menu-item index="/classdataList">
								<i class="el-icon-warning"></i>
								<span slot="title">班级数据</span>
							</el-menu-item>
							<el-menu-item index="/hisclassdataList">
								<i class="el-icon-warning"></i>
								<span slot="title">历史数据</span>
							</el-menu-item>
							<!--              <el-menu-item index="/eyetestList">
                <i class="el-icon-warning"></i>
                <span slot="title">导出长编号</span>
              </el-menu-item> -->
						</el-menu-item-group>
					</el-submenu>


					<el-submenu index="30" v-if="isRole(['teacher'])">
						<template slot="title">
							<i class="el-icon-setting"></i>
							<span slot="title">视力监测</span>
						</template>
						<el-menu-item-group>
							<el-menu-item index="/classStudentDataListTea">
								<i class="el-icon-warning"></i>
								<span slot="title">班级数据</span>
							</el-menu-item>
						</el-menu-item-group>
					</el-submenu>
					

					<el-submenu index="99" v-if="isRole(['teacher'])">
						<template slot="title">
							<i class="el-icon-setting"></i>
							<span slot="title">健康档案</span>
						</template>
						<el-menu-item-group>
							<el-menu-item index="/classHealth">
								<i class="el-icon-warning"></i>
								<span slot="title">健康档案</span>
							</el-menu-item>
						</el-menu-item-group>
					</el-submenu>
					<el-submenu index="98" v-if="isRole(['teacher'])">
						<template slot="title">
							<i class="el-icon-setting"></i>
							<span slot="title">运动记录</span>
						</template>
						<el-menu-item-group>
							<el-menu-item index="/classSport">
								<i class="el-icon-warning"></i>
								<span slot="title">运动记录</span>
							</el-menu-item>
						</el-menu-item-group>
					</el-submenu>
					<el-submenu index="31" v-if="isRole(['teacher'])">
						<template slot="title">
							<i class="el-icon-setting"></i>
							<span slot="title">数据管理</span>
						</template>
						<el-menu-item-group>
							<el-menu-item index="/classStudentMeasurementData">
								<i class="el-icon-warning"></i>
								<span slot="title">测温数据</span>
							</el-menu-item>
						</el-menu-item-group>
					</el-submenu>

					

					<!--          <el-submenu index="4"
            v-if=" Status == 'teacher' ? true : Status == 'teacherAgent' ? true : Status == 'school' ? true : false">
            <template slot="title">
              <i class="el-icon-setting"></i>
              <span slot="title">数据管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/eyetestList">
                <i class="el-icon-warning"></i>
                <span slot="title">数据列表</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu> -->

					<!-- <el-submenu index="4" v-if="Status=='agent'?false:true">
                   <template slot="title">
              <i class="el-icon-setting"></i>
              <span slot="title">文章管理</span>
            </template>
              <el-menu-item-group>
                   <el-menu-item index="/articleRegulate">
                <i class="el-icon-warning"></i>
                <span slot="title">我的文章</span>
              </el-menu-item>
                 <el-menu-item index="/WriteArticle">
                <i class="el-icon-warning"></i>
                <span slot="title">创作文章</span>
              </el-menu-item>
                </el-menu-item-group>
                </el-submenu> -->

					<!-- <el-submenu index="5" v-if="Status == 'school' ? true : false">
            <template slot="title">
              <i class="el-icon-setting"></i>
              <span slot="title">系统设置</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/safetySetting">
                <i class="el-icon-warning"></i>
                <span slot="title">个人设置</span>
              </el-menu-item>
              <el-menu-item index="/operationLog">
                <i class="el-icon-warning"></i>
                <span slot="title">操作日志</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>-->

					<!-- <el-submenu index="6" v-if="
              Status == 'teacher' ? true : Status == 'teacherAgent' ? true : false">
						<template slot="title">
							<i class="el-icon-setting"></i>
							<span slot="title">教师设置</span>
						</template>
						<el-menu-item-group>
							<el-menu-item index="/personalCenter">
                <i class="el-icon-warning"></i>
                <span slot="title">个人中心</span>
              </el-menu-item>
							<el-menu-item index="/personalSetting">
								<i class="el-icon-warning"></i>
								<span slot="title">个人设置</span>
							</el-menu-item>
						</el-menu-item-group>
					</el-submenu>-->

					<el-submenu index="7"  v-if="isRole(['agent', 'adv', 'teacher'])">
						<template slot="title">
							<i class="el-icon-setting"></i>
							<span slot="title">设备管理</span>
						</template>
						<el-menu-item-group>
							<el-menu-item index="/facilityList">
								<i class="el-icon-warning"></i>
								<span slot="title">设备列表</span>
							</el-menu-item>
							<el-menu-item index="/Agent_SchoolList">
								<i class="el-icon-warning"></i>
								<span slot="title">学校设备列表</span>
							</el-menu-item>

							<!-- 打开下面注释就好使 -->
							<!--              <el-menu-item index="/Agent_ShopList">
                <i class="el-icon-warning"></i>
                <span slot="title">门店设备列表</span>
              </el-menu-item>
              <el-menu-item index="/Agent_ZzdList">
                <i class="el-icon-warning"></i>
                <span slot="title">自助点设备列表</span>
              </el-menu-item> -->
						</el-menu-item-group>
					</el-submenu>

					<el-submenu index="8" v-if="isRole(['agent', 'adv', 'teacher'])">
						<template slot="title">
							<i class="el-icon-setting"></i>
							<span slot="title">学校管理</span>
						</template>
						<el-menu-item-group>
							<el-menu-item index="/school_list">
								<i class="el-icon-warning"></i>
								<span slot="title">学校列表</span>
							</el-menu-item>
							<!--              <el-menu-item index="/Agent_enterSchool">
                <i class="el-icon-warning"></i>
                <span slot="title">添加学校</span>
              </el-menu-item> -->
							<el-menu-item index="/holiday-setting">
								<i class="el-icon-warning"></i>
								<span slot="title">节假日设置</span>
							</el-menu-item>
						</el-menu-item-group>
					</el-submenu>

					<!-- 打开下面注释就好使 员工管理没做 -->
					<!--       <el-submenu index="24" v-if="
              Status == 'agent' ? true :Status == 'agentAdv' ? true: Status == 'teacherAgent' ? true : false">
            <template slot="title">
              <i class="el-icon-setting"></i>
              <span slot="title">门店管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/shop_list">
                <i class="el-icon-warning"></i>
                <span slot="title">门店列表</span>
              </el-menu-item>
              <el-menu-item index="/manage_list">
                <i class="el-icon-warning"></i>
                <span slot="title">员工管理</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu> -->

					<!-- 打开下面注释就好使 -->
					<!-- <el-submenu index="25" v-if="
              Status == 'agent' ? true :Status == 'agentAdv' ? true: Status == 'teacherAgent' ? true : false">
            <template slot="title">
              <i class="el-icon-setting"></i>
              <span slot="title">自助点管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/zzd_list">
                <i class="el-icon-warning"></i>
                <span slot="title">自助点列表</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu> -->

					<el-submenu index="23" v-if="isRole(['agent', 'adv', 'teacher'])">
						<template slot="title">
							<i class="el-icon-setting"></i>
							<span slot="title">数据管理</span>
						</template>
						<el-menu-item-group>

							<el-menu-item index="/equipment_management">
								<i class="el-icon-menu"></i>
								<span slot="title">设备数据管理</span>
							</el-menu-item>
						</el-menu-item-group>
						<el-menu-item-group>

							<el-menu-item index="/school_management">
								<i class="el-icon-menu"></i>
								<span slot="title">学校数据管理</span>
							</el-menu-item>
						</el-menu-item-group>

					</el-submenu>

					<el-submenu index="19" v-if="isRole(['agent', 'adv', 'teacher'])">
						<template slot="title">
							<i class="el-icon-setting"></i>
							<span slot="title">财务管理</span>
						</template>
						<el-menu-item-group>

							<el-menu-item index="/daily_revenue">
								<i class="el-icon-menu"></i>
								<span slot="title">每日收益</span>
							</el-menu-item>
						</el-menu-item-group>
						<el-menu-item-group>

							<el-menu-item index="/school_income">
								<i class="el-icon-menu"></i>
								<span slot="title">学校收益</span>
							</el-menu-item>
						</el-menu-item-group>
					</el-submenu>

					<!--        <el-submenu index="14" v-if="
					Status == 'agent' ? true :Status == 'agentAdv' ? true: Status == 'teacherAgent' ? true : false">
            <template slot="title">
              <i class="el-icon-setting"></i>
              <span slot="title">授权用户 暂时不要 范 说改为代理查看门店下面授权的学生列表</span>
            </template>
            <el-menu-item-group>

              <el-menu-item index="/health_list">
                <i class="el-icon-menu"></i>
                <span slot="title">健康列表</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu> -->

					<!--          <el-submenu index="11" v-if="
					Status == 'agent' ? true :Status == 'agentAdv' ? true: Status == 'teacherAgent' ? true : false">
            <template slot="title">
              <i class="el-icon-setting"></i>
              <span slot="title">安防信息列表</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/securityHistory">
                <i class="el-icon-warning"></i>
                <span slot="title">安防记录</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu> -->

					<!--          <el-submenu index="9" v-if="
              Status == 'agent' ? true :Status == 'agentAdv' ? true : Status == 'teacherAgent' ? true : false">
            <template slot="title">
              <i class="el-icon-setting"></i>
              <span slot="title">我的收益</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/agentAcceptGet">
                <i class="el-icon-warning"></i>
                <span slot="title">收益提现</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu> -->

					<el-submenu index="13" v-if="isRole(['agent', 'school', 'teacher'])">

						<template slot="title">
							<i class="el-icon-setting"></i>
							<span slot="title">出勤管理
							</span>
						</template>
						<el-menu-item-group v-if="isRole(['agent', 'school', 'teacher'])">
							<el-submenu index="13-1">
								<template slot="title">学生出勤管理
								</template>
								<el-menu-item :index="Status == 'school' ? '/classHistory' : '/studentHistory'">
									<i class="el-icon-warning"></i>
									<span slot="title">入离校记录</span>
								</el-menu-item>
							</el-submenu>
						</el-menu-item-group>
						<el-menu-item-group v-if="isRole(['agent', 'school', 'teacher'])">
							<el-submenu index="13-2">
								<template slot="title">教师出勤管理</template>
								<el-menu-item index="/arriveLeaveHistory">
									<i class="el-icon-warning"></i>
									<span slot="title">记录列表</span>
								</el-menu-item>
							</el-submenu>
						</el-menu-item-group>

						<el-menu-item-group v-if="isRole(['teacher'])">
							<el-menu-item :index="Status == 'school' ? '/classHistory' : '/studentHistory'">
								<i class="el-icon-warning"></i>
								<span slot="title">入离校记录</span>
							</el-menu-item>
						</el-menu-item-group>


					</el-submenu>

					<el-submenu index="16" v-if="isRole(['teacher'])">

						<template slot="title">
							<i class="el-icon-setting"></i>
							<span slot="title">成绩发布
							</span>
						</template>

						<el-menu-item-group>
							<el-menu-item index="/examList">
								<i class="el-icon-warning"></i>
								<span slot="title">成绩列表</span>
							</el-menu-item>
						</el-menu-item-group>

						<el-menu-item-group>
							<el-menu-item index="/uploadExam">
								<i class="el-icon-warning"></i>
								<span slot="title">上传成绩</span>
							</el-menu-item>
						</el-menu-item-group>


					</el-submenu>
					
					<el-submenu index="17" v-if="isRole(['teacher'])">

<template slot="title">
	<i class="el-icon-setting"></i>
	<span slot="title">发送通知
	</span>
</template>

<el-menu-item-group>
	<el-menu-item index="/noticeList">
		<i class="el-icon-warning"></i>
		<span slot="title">通知管理</span>
	</el-menu-item>
</el-menu-item-group>

<el-menu-item-group>
	<el-menu-item index="/sendNotice">
		<i class="el-icon-warning"></i>
		<span slot="title">发送通知</span>
	</el-menu-item>
</el-menu-item-group>


</el-submenu>
					<!--          <el-submenu index="12" v-if="Status == 'school' ? true : false">
            <template slot="title">
              <i class="el-icon-setting"></i>
              <span slot="title">教职工出入信息</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/arriveLeaveHistory">
                <i class="el-icon-warning"></i>
                <span slot="title">记录列表</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>

          <el-submenu index="13" v-if="Status == 'teacher' ? true :
					Status == 'teacherAgent' ? true : Status == 'school' ? true : false">
            <template slot="title">
              <i class="el-icon-setting"></i>
              <span slot="title">学生出入信息</span>
            </template>
            <el-menu-item-group>
              <el-menu-item :index="Status == 'school' ? '/classHistory' : '/studentHistory'">
                <i class="el-icon-warning"></i>
                <span slot="title">入离校记录</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu> -->

					<el-submenu index="10" v-if="isRole(['school'])">
						<template slot="title">
							<i class="el-icon-setting"></i>
							<span slot="title">学校设置</span>
						</template>
						<el-menu-item-group>
							<el-menu-item index="/setTime">
								<i class="el-icon-warning"></i>
								<span slot="title">设置到校离校时间</span>
							</el-menu-item>
							<el-menu-item index="/set-holiday-time">
								<i class="el-icon-warning"></i>
								<span slot="title">节假日设置</span>
							</el-menu-item>
							<el-menu-item index="/create-campus">
								<i class="el-icon-warning"></i>
								<span slot="title">创建校区</span>
							</el-menu-item>

						</el-menu-item-group>
					</el-submenu>

					<el-menu-item @click="blank()" v-if="isRole(['teacher', 'agent', 'adv'])">
						<i class="el-icon-setting"></i>
						<span slot="title">广告平台</span>
					</el-menu-item>

					<el-submenu index="15" v-if="isRole(['teacher', 'agent', 'adv'])">
						<template slot="title">
							<i class="el-icon-setting"></i>
							<span slot="title">操作日志</span>
						</template>
						<el-menu-item-group>
							<el-menu-item index="/healthlogList">
								<i class="el-icon-menu"></i>
								<span slot="title">健康日志</span>
							</el-menu-item>
							<el-menu-item index="/schoollogList">
								<i class="el-icon-menu"></i>
								<span slot="title">学校列表</span>
							</el-menu-item>
							<el-menu-item index="/machinelogList">
								<i class="el-icon-menu"></i>
								<span slot="title">设备列表</span>
							</el-menu-item>
							<el-menu-item index="/loginlogList">
								<i class="el-icon-menu"></i>
								<span slot="title">登录日志</span>
							</el-menu-item>
						</el-menu-item-group>
					</el-submenu>
					<!--<el-submenu index="2">
            <template slot="title">
              <i class="el-icon-setting"></i>
              <span slot="title">文章管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/articleComplete">
                <i class="el-icon-warning"></i>
                <span slot="title">待审核</span>
              </el-menu-item>
              <el-menu-item index="UNarticle">
                <i class="el-icon-circle-check"></i>
                <span slot="title">已审核</span>
              </el-menu-item>
                <el-menu-item index="refuseArticle">
                <i class="el-icon-circle-close"></i>
                <span slot="title">已拒绝</span>
              </el-menu-item>
               <el-menu-item index="freeze">
                <i class="el-icon-lock"></i>
                <span slot="title">已冻结</span>
              </el-menu-item> -->
					<!-- <el-menu-item index="Addclassify">
                <i class="el-icon-circle-plus-outline"></i>
                <span slot="title">添加分类</span>
              </el-menu-item> -->
					<!-- </el-menu-item-group> -->
					<!-- </el-submenu> -->

					<el-menu-item index="/publishArticles" v-if="isRole(['teacher'])">
						<i class="el-icon-setting"></i>
						<span>发布文章</span>
					</el-menu-item>

					<el-menu-item index="/set_user" v-if="isRole(['teacher', 'school'])">
						<i class="el-icon-location"></i>
						<span>设置</span>
					</el-menu-item>

					<el-submenu index="18" v-if="isRole(['teacher', 'agent', 'adv'])">
						<template slot="title">
							<i class="el-icon-setting"></i>
							<span slot="title">设置</span>
						</template>
						<el-menu-item-group>
							<el-menu-item index="/setting_modification">
								<i class="el-icon-menu"></i>
								<span slot="title">设置修改</span>
							</el-menu-item>
						</el-menu-item-group>
					</el-submenu>


				</el-menu>
			</el-col>
		</el-row>
	</div>
</template>
<script>
import {
	mapGetters
} from "vuex";
export default {
	props: ["isCollapse"],
	data() {
		return {
			currentPath: '/SchoolHome',
			aaa: '',
			role: []
		};
	},
	mounted() {
		if (localStorage.getItem('hpath') != null) {
			this.aaa = localStorage.getItem('hpath');
		} else {
			this.aaa = location.hash.substring(1); //从第1个字符开始,截取到最后个字符;
			localStorage.setItem('hpath', this.aaa)
		}
		const userInfo = JSON.parse(localStorage.getItem('userInfo'));
		this.role = userInfo.role;
		
	},
	computed: {
		...mapGetters(["Status", "scIndex", "teaIndex", "agentIndex"]),
		mapboxMap1(newData, oldData) { },
		
	},
	watch: {
		mapboxMap1(newData, oldData) { },
		$route: "getPath", //监听路由，路由发生变化，就调那个的方法
	},
	methods: {

		isRole(roleArr = []) {
			const roleSet = new Set(roleArr)	
			return this.role.some(role => roleSet.has(role))
		},
		isAllRole(roleArr = []) {
			const roleSet = new Set(roleArr)
			return this.role.every(role => roleSet.has(role))
		},
		getPath() {
			// this.$forceupdate();
			this.aaa = localStorage.getItem('hpath');
		},
		handleOpen(key, keyPath) {
			// console.log(key, keyPath)
		},
		handleClose(key, keyPath) {
			// console.log(key, keyPath)
		},
		blank() {
			let routeData = this.$router.resolve("/advertising/adverList");
			window.open(routeData.href, '_blank');
		},
		vvvv(e) {
			localStorage.setItem('hpath', e)
		},
		async screen() { //大屏跳转

			//console.log(localStorage.getItem('token'))

			const tokens = encodeURIComponent(localStorage.getItem('token'));

			//const syurl = encodeURIComponent('http://192.168.1.80:8081/#/login' + "?addr=3"); //url转义 // url转义解码用 decodeURIComponent(str) token失败返回本地地址

			//const syurl = encodeURIComponent('http://shiyun.dljianwei.com/html/#/login' + "?addr=3"); //token失败返回测试地址

			//const syurl = encodeURIComponent('http://sygp.shiyuntech.net/html/#/login' + "?addr=3"); //token失败正式地址
			const syurl = encodeURIComponent(this.$url + '/html/#/login' + "?addr=3"); //token失败正式地址

			const scurl = this.$url == "http://sygp.shiyuntech.net" ? "http://sygpweb.shiyuntech.net/" :
				"http://shiyunweb.dljianwei.com";
			window.open(scurl + "?token=" + tokens + "&url=" + syurl, "_blank");

		}


	},
};
</script>
<style lang="scss" scoped>
.menu-nav:not(.el-menu--collapse) {
	width: 200px;
	min-height: 400px;
	text-align: left;
}

.el-menu {
	border: none;
}
</style>
